<template>
  <div>
    <h3
      class="primary--text pt-6 pb-3 text-center"
    >
      {{ $t('result.title') }}
    </h3>
    <v-container>
      <Additive
        v-if="additive"/>
      <PathDependent
        v-else/>
      <MoreTests />
    </v-container>
    <Email
      :dialog="emailDialog"
      @close="emailDialog = false"
    />
    <Rating
      :dialog="ratingDialog"
      @close="ratingDialog = false"
    />
    <StickyButton
      @email="emailDialog = true"
      @rating="ratingDialog = true"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';

export default {
  name: 'Result',
  components: {
    Additive: lazyLoad('views/Result/Additive'),
    PathDependent: lazyLoad('views/Result/PathDependent'),
    MoreTests: lazyLoad('components/MoreTests'),
    Email: lazyLoad('components/Dialogs/Email'),
    Rating: lazyLoad('components/Dialogs/Rating'),
    StickyButton: lazyLoad('components/Layout/StickyButton'),
  },
  data: () => ({
    emailDialog: false,
    ratingDialog: false,
  }),
  computed: {
    ...mapGetters('testMacro', [
      'additive',
    ]),
  },
};
</script>
